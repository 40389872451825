import FingerprintJS from '@fingerprintjs/fingerprintjs';

declare const requestIdleCallback: any;

function setCookie(name: string, value: string, days: number) {
  let expires = '';

  if (days) {
    const date = new Date();

    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

function setId() {
  const fpPromise = FingerprintJS.load();

  fpPromise
    .then((fp) => fp.get())
    .then(({ visitorId }) => {
      setCookie('fp', visitorId, 1);
    });
}

if ((window as any).requestIdleCallback) {
  requestIdleCallback(() => {
    setId();
  });
} else {
  setTimeout(() => {
    setId();
  }, 500);
}
